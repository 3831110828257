<template>
  <div class="md-layout-item md-size-50 md-large-size-100 md-small-size-100">
    <!--form-->
    <template v-if="oper==='upd'">
      <div :style="{'padding-left':0, 'padding-right':0}"
           class="md-layout-item md-layout md-size-100 md-alignment-top-space-between md-gutter">
        <md-button class="md-success md-layout-item md-size-30 md-small-size-100" @click="inventoryInspection">
          {{ $t('button.inventory_inspection') }}
        </md-button>
        <md-button class="md-success md-layout-item md-size-30 md-small-size-100" @click="conditionInspection">
          {{ $t('button.condition_inspection') }}
        </md-button>
        <md-button class="md-success md-layout-item md-size-30  md-small-size-100" @click="interventionInspection">
          {{ $t('button.intervention_inspection') }}
        </md-button>
      </div>
    </template>
    <div class="">
      <div :style="{'padding-left':0, 'padding-right':0}" class="">
        <md-card>
          <md-card-header>
            <div class="md-headline card-title"> {{ $t("bridges.bridge_identification_info") | UpCase }}</div>
            <div class="toolbar-icon">
              <div :title="$t('button.export')" @click.prevent="onExport"><i class="fas fa-file-export"></i></div>
              <div :title="$t('button.files')" @click.prevent="onFilesList">
                <i class="fas fa-folder-open"></i>
              </div>
            </div>

          </md-card-header>
          <md-card-content>
            <md-field
                :class="[
                  { 'md-valid': !errors.has('bridgeId') && touched.bridgeId },
                  { 'md-error': errors.has('bridgeId') }
                  ]">
              <label>
                {{ $t(`bridges.bridge_id`) }}
              </label>
              <md-input
                  v-model="bridge.bridgeId"
                  v-validate="modelValidations.bridgeId"
                  :disabled="true"
                  data-vv-name="bridgeId"
                  type="text"
              ></md-input>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('bridgeId')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <md-field
                :class="[
                  { 'md-valid': !errors.has('inspectorName') && touched.inspectorName },
                  { 'md-error': errors.has('inspectorName') }
                  ]">
              <label>
                {{ $t(`bridges.inspectorName`) }}
              </label>
              <md-select
                  v-model="bridge.inspectorName"
                  v-validate="modelValidations.inspectorName"
                  :data-vv-name="'inspectorName'"
              >
                <md-option v-for="inspector in inspector_list" :key="inspector.id" :value="inspector.id">
                  {{ inspector.name }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('inspectorName')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <!--div>
              <md-datepicker
                  v-model="bridge.dateOfInspection"
                  v-validate="modelValidations.dateOfInspection"
                  :class="[
                  { 'md-valid': !errors.has('dateOfInspection') && touched.dateOfInspection },
                  { 'md-error': errors.has('dateOfInspection') }
                  ]"
                  md-immediately
                  name="dateOfInspector">
                <label>
                  {{ $t(`bridges.dateOfInspection`) }}
                </label>
                <slide-y-down-transition>
                  <md-icon v-show="errors.has('dateOfInspection')" class="error">close</md-icon>
                </slide-y-down-transition>
              </md-datepicker>

            </div-->
            <md-field
                :class="[
                    { 'md-valid': !errors.has('inspectorName') && touched.inspectorName },
                    { 'md-error': errors.has('inspectorName') }
                    ]">
              <label>
                {{ $t(`bridges.sourceOfInformation`) }}
              </label>
              <md-select
                  v-model="bridge.sourceOfInformation"
                  v-validate="modelValidations.sourceOfInformation"
                  :data-vv-name="'sourceOfInformation'"
              >
                <md-option v-for="name in sourceOfInformation" :key="name" :value="name">
                  {{ $t(`bridges.${name}`) }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('sourceOfInformation')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
          </md-card-content>
        </md-card>
      </div>
      <div :style="{'padding-left':0, 'padding-right':0}" class="">
        <md-card>
          <md-card-header>
            <div class="md-title card-title">{{ $t("bridges.bridge_location_details") | UpCase }}</div>
          </md-card-header>
          <md-card-content>
            <md-field
                :class="[
                  { 'md-valid': !errors.has('roadCode') && touched.roadCode },
                  { 'md-error': errors.has('roadCode') }
                  ]">
              <label>
                {{ $t(`bridges.road`) }}
              </label>
              <md-select
                  v-model="bridge.roadCode"
                  v-validate="modelValidations.roadCode"
                  :data-vv-name="'roadCode'"
              >
                <md-option v-for="road in roadsList" :key="road.road_id" :value="road.road_id">
                  {{ `${road.road_category} / ${road.road_name}` }}
                </md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('roadCode')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <div class=" md-layout md-small-size-100 md-size-50 md-alignment-center-space-between">
              <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-45">
                <md-field :class="[
                  { 'md-valid': !errors.has('gpsCoordinatesLat') && touched.gpsCoordinatesLat },
                  { 'md-error': errors.has('gpsCoordinatesLat') }
                  ]">
                  <label>
                    {{ $t(`bridges.gpsCoordinatesLat`) }}
                  </label>
                  <md-input
                      v-model="bridge.gpsCoordinatesLat"
                      v-validate="modelValidations.gpsCoordinatesLat"
                      data-vv-name="gpsCoordinatesLat"
                      type="number"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon v-show="errors.has('gpsCoordinatesLat')" class="error">close</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-45">
                <md-field :class="[
                  { 'md-valid': !errors.has('gpsCoordinatesLon') && touched.gpsCoordinatesLon },
                  { 'md-error': errors.has('gpsCoordinatesLon') }
                  ]">
                  <label>
                    {{ $t(`bridges.gpsCoordinatesLon`) }}
                  </label>
                  <md-input
                      v-model="bridge.gpsCoordinatesLon"
                      v-validate="modelValidations.gpsCoordinatesLon"
                      data-vv-name="gpsCoordinatesLon"
                      type="number"
                  ></md-input>
                  <slide-y-down-transition>
                    <md-icon v-show="errors.has('gpsCoordinatesLon')" class="error">close</md-icon>
                  </slide-y-down-transition>
                </md-field>
              </div>
              <div :style="{'padding-right':0}" class="md-layout-item md-layout md-small-size-100 md-alignment-center">
                <md-button
                    class="md-just-icon md-primary"
                    @click="getCoord"
                    :disabled="!doNavigatorEnable">
                  <i class="fas fa-location-arrow"
                     :class="{'fas fa-sync-alt fa-spinner': isCoordGetting}"></i>

                </md-button>
              </div>
            </div>
            <!--div class=" md-layout md-small-size-100 md-size-50">
              <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>
                    {{ $t(`bridges.province`) }}
                  </label>
                  <md-select v-model="bridge.provinceId" @md-selected="onProvinceChanged">
                    <md-option v-for="prov in locationProvinces" :key="prov.id" :value="prov.id">
                      {{ prov.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
              <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>
                    {{ $t(`bridges.district`) }}
                  </label>
                  <md-select v-model="bridge.districtId" @md-selected="onDistrictChanged">
                    <md-option v-for="distr in districtsReduced" :key="distr.id" :value="distr.id">
                      {{ distr.name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class=" md-layout md-small-size-100 md-size-50">
              <div class=" md-layout md-small-size-100 md-size-50">
                <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>
                      {{ $t(`bridges.sector`) }}
                    </label>
                    <md-select v-model="bridge.sectorId" @md-selected="onSectorChanged">
                      <md-option v-for="sec in sectorsReduced" :key="sec.id" :value="sec.id">
                        {{ sec.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div :style="{'padding-left':0, 'padding-right':0}" class="md-layout-item md-small-size-100 md-size-50">
                  <md-field>
                    <label>
                      {{ $t(`bridges.cell`) }}
                    </label>
                    <md-select v-model="bridge.cellId">
                      <md-option v-for="cell in cellsReduced" :key="cell.id" :value="cell.id">
                        {{ cell.name }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>

              </div>
            </div-->
            <md-field
                :class="[
                  { 'md-valid': !errors.has('bridgeName') && touched.bridgeName },
                  { 'md-error': errors.has('bridgeName') }
                  ]">
              <label>
                {{ $t(`bridges.bridgeName`) }}
              </label>
              <md-input
                  v-model="bridge.bridgeName"
                  v-validate="modelValidations.bridgeName"
                  data-vv-name="bridgeName"
                  type="text"
              ></md-input>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('bridgeName')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <md-field>
              <label>
                {{ $t(`bridges.typeOfCrossing`) }}
              </label>
              <md-select
                  v-model="bridge.typeOfCrossing">
                <md-option v-for="type in typesOfCrossing" :key="type" :value="type">{{
                    $t(`bridges.${type}`)
                  }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>
                {{ $t(`bridges.principalFeature`) }}
              </label>
              <md-select
                  v-model="bridge.principalFeature">
                <md-option v-for="type in principalFeatures" :key="type" :value="type">{{
                    $t(`bridges.${type}`)
                  }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>
                {{ $t(`bridges.secondaryFeature`) }}
              </label>
              <md-select
                  v-model="bridge.secondaryFeature">
                <md-option v-for="type in secondaryFeatures" :key="type" :value="type">{{ $t(`bridges.${type}`) }}
                </md-option>
              </md-select>
            </md-field>
            <md-field>
              <label>
                {{ $t(`bridges.featureCrossed`) }}
              </label>
              <md-select
                  v-model="bridge.featureCrossed">
                <md-option v-for="type in featuresCrossed" :key="type" :value="type">{{
                    $t(`bridges.${type}`)
                  }}
                </md-option>
              </md-select>
            </md-field>

          </md-card-content>
        </md-card>
      </div>
      <div :style="{'padding-left':0, 'padding-right':0}" class="">
        <md-card>
          <md-card-header>
            <div class="md-title card-title">{{ $t("bridges.design_and_construction_details") | UpCase }}</div>
          </md-card-header>
          <md-card-content>
            <md-field
                :class="[
                { 'md-valid': !errors.has('numberOfSpans') && touched.numberOfSpans },
                { 'md-error': errors.has('numberOfSpans') }
                ]">
              <label>
                {{ $t(`bridges.numberOfSpans`) }}
              </label>
              <md-select
                  v-model="bridge.numberOfSpans"
                  v-validate="modelValidations.numberOfSpans"
                  :data-vv-name="'numberOfSpans'"
              >
                <md-option v-for="num in 10" :key="num" :value="num">{{ num }}</md-option>
              </md-select>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('numberOfSpans')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <div v-for="field in thirdCardFields" :key="field.name">
              <template v-if="field.elem==='switch'">
                <md-switch v-model="bridge[field.name]" class="md-success" style="padding-bottom: 20px">
                  {{ $t(`bridges.${field.name}`) }}
                </md-switch>
              </template>
              <template v-else>
                <template v-if="field.name==='rehabilitationType'">
                  <md-field
                      :class="[
                { 'md-valid': !errors.has('rehabilitationType') && touched.rehabilitationType },
                { 'md-error': errors.has('rehabilitationType') }
                ]">
                    <label>
                      {{ $t(`bridges.rehabilitationType`) }}
                    </label>
                    <md-select
                        v-model="bridge.rehabilitationType"
                        v-validate="modelValidations.rehabilitationType"
                        :data-vv-name="'rehabilitationType'"
                    >
                      <md-option v-for="type in rehabilitationTypes" :key="type" :value="type">
                        {{ $t(`bridge.rehabilitation_${type}`) }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon v-show="errors.has('rehabilitationType')" class="error">close</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </template>
                <template v-else-if="field.name==='typeOfAssessment'">
                  <md-field
                      :class="[
                { 'md-valid': !errors.has('typeOfAssessment') && touched.typeOfAssessment },
                { 'md-error': errors.has('typeOfAssessment') }
                ]">
                    <label>
                      {{ $t(`bridges.typeOfAssessment`) }}
                    </label>
                    <md-select
                        v-model="bridge.typeOfAssessment"
                        v-validate="modelValidations.typeOfAssessment"
                        :data-vv-name="'typeOfAssessment'"
                    >
                      <md-option v-for="type in assessmentTypes" :key="type" :value="type">
                        {{ $t(`bridge.assessment_${type}`) }}
                      </md-option>
                    </md-select>
                    <slide-y-down-transition>
                      <md-icon v-show="errors.has('typeOfAssessment')" class="error">close</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </template>
                <template v-else>
                  <md-field
                      :class="[
                { 'md-valid': !errors.has(field.name) && touched[field.name] && field.required},
                { 'md-error': errors.has(field.name) && field.required}
                ]">
                    <label>
                      {{ $t(`bridges.${field.name}`) }}
                    </label>
                    <md-input
                        v-model="bridge[field.name]"
                        v-validate="modelValidations[field.name]"
                        :data-vv-name="field.name"
                        type="field.type"
                    ></md-input>
                    <template v-if="field.required">
                      <slide-y-down-transition>
                        <md-icon v-show="errors.has(field.name)" class="error">close</md-icon>
                      </slide-y-down-transition>
                    </template>
                  </md-field>
                </template>
              </template>
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div :style="{'padding-left':0, 'padding-right':0}" class="">
        <md-card>
          <md-card-header>
            <div class="md-title card-title">{{ $t("bridges.scour_reliability") | UpCase }}</div>
          </md-card-header>
          <md-card-content>
            <div v-for="field in scourReliabilityCardSwitches" :key="field.name">
                <md-switch v-model="bridge[field.name]" class="md-success" style="padding-bottom: 20px" @change="onScourSwitchToggled">
                  {{ $t(`bridges.${field.name}`) }}
                </md-switch>
            </div>
            <md-field
                :class="[
                { 'md-valid': !errors.has('scourExposureClass') && touched.scourExposureClass},
                { 'md-error': errors.has('scourExposureClass') }
                ]">
              <label>
                {{ $t(`bridges.scourExposureClass`) }}
              </label>
              <md-input
                  :value="bridge.scourExposureClass"
                  v-validate="modelValidations.scourExposureClass"
                  :data-vv-name="'scourExposureClass'"
                  :type="'number'"
                  @input="onChangeScourExposureClass"
                  min="1" max="5"
              ></md-input>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('scourExposureClass')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <div v-for="field in scourReliabilityCardFields" :key="field.name">
              <template v-if="field.elem==='switch'">
                <md-switch v-model="bridge[field.name]" class="md-success" style="padding-bottom: 20px" @change="onScourSwitchToggled">
                  {{ $t(`bridges.${field.name}`) }}
                </md-switch>
              </template>
              <template v-else-if="field.elem==='select'">
                <md-field>
                  <label>
                    {{ $t(`bridges.${field.name}`) }}
                  </label>
                  <md-select
                      v-model="bridge[field.name]"
                      :data-vv-name="field.name"
                  >
                    <md-option v-for="type in field.options" :key="type" :value="type">
                      {{ $t(`bridge.scour_pos_${type}`) }}
                    </md-option>
                  </md-select>
                </md-field>
              </template>
              <!--template v-else>
                <md-field
                    :class="[
                { 'md-valid': !errors.has(field.name) && touched.bridgeName && field.required},
                { 'md-error': errors.has(field.name) && field.required}
                ]">
                  <label>
                    {{ $t(`bridges.${field.name}`) }}
                  </label>
                  <md-input
                      v-model="bridge[field.name]"
                      v-validate="modelValidations[field.name]"
                      :data-vv-name="field.name"
                      :type="field.type"
                  ></md-input>
                  <template v-if="field.required">
                    <slide-y-down-transition>
                      <md-icon v-show="errors.has(field.name)" class="error">close</md-icon>
                    </slide-y-down-transition>
                  </template>
                </md-field>
              </template-->
            </div>
            <md-field
                :class="[
                { 'md-valid': !errors.has('scourResistanceClass') && touched.scourResistanceClass},
                { 'md-error': errors.has('scourResistanceClass') }
                ]">
              <label>
                {{ $t(`bridges.scourResistanceClass`) }}
              </label>
              <md-input
                  v-model="bridge.scourResistanceClass"
                  v-validate="modelValidations.scourResistanceClass"
                  :data-vv-name="'scourResistanceClass'"
                  :type="'number'"
                  min="1" max="5"
              ></md-input>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('scourResistanceClass')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>
            <md-field
                :class="[
                { 'md-valid': !errors.has('scourResistance') && touched.scourResistance},
                { 'md-error': errors.has('scourResistance') }
                ]">
              <label>
                {{ $t(`bridges.scourResistance`) }}
              </label>
              <md-input
                  v-model="bridge.scourResistance"
                  v-validate="modelValidations.scourResistance"
                  :data-vv-name="'scourResistance'"
                  :type="'number'"
                  min="1" max="5"
              ></md-input>
              <slide-y-down-transition>
                <md-icon v-show="errors.has('scourResistance')" class="error">close</md-icon>
              </slide-y-down-transition>
            </md-field>

          </md-card-content>
        </md-card>
      </div>

      <div :style="{'padding-left':0, 'padding-right':0}" class="">
        <md-card>
          <md-card-header>
            <div class="md-headline card-title"> {{ $t("bridges.archive_data") | UpCase }}</div>
          </md-card-header>
          <md-card-content>
            <md-switch v-model="bridge.asBuiltDrawingsAvailable" class="md-success">
              {{ $t("bridges.as_built_drawings_available") }}
            </md-switch>
            <md-switch v-model="bridge.microfilmAvailable" class="md-success">{{ $t("bridges.microfilm_available") }}
            </md-switch>
            <md-switch v-model="bridge.washedOutBefore" class="md-success">
              {{ $t("bridges.bridge_washed_out_before") }}
            </md-switch>
          </md-card-content>
        </md-card>
      </div>

      <md-card-actions md-alignment="left">
        <md-button
            class="md-success"
            native-type="submit"
            @click.native.prevent="validate"
        >{{ $t(`button.save`) }}
        </md-button
        >
        <md-button
            class="md-default"
            @click.native.prevent="back"
        >{{ $t("button.back") }}
        </md-button
        >
      </md-card-actions>
    </div>
    <!--/form-->
  </div>
</template>

<script>
import {SlideYDownTransition} from "vue2-transitions"
import Swal from "sweetalert2"
import XLSX from "xlsx"

export default {
  name: "bridgeProfileForm",
  components: {
    SlideYDownTransition,
  },
  props: {
    oper: String
  },
  watch: {
    ['bridge.bridgeId']() {
      this.touched.bridgeId = true
    },
    ['bridge.inspectorName']() {
      this.touched.inspectorName = true
    },
    // ['bridge.dateOfInspection']() {
    //   this.touched.dateOfInspection = true
    // },
    ['bridge.sourceOfInformation']() {
      this.touched.sourceOfInformation = true
    },
    ['bridge.bridgeName']() {
      this.touched.bridgeName = true
    },
    ['bridge.overallLength']() {
      this.touched.overallLength = true
    },
    ['bridge.lengthBetweenAbutments']() {
      this.touched.lengthBetweenAbutments = true
    },
    ['bridge.totalDeckWidth']() {
      this.touched.totalDeckWidth = true
    },
    ['bridge.sidewalkWidthLeft']() {
      this.touched.sidewalkWidthLeft = true
    },
    ['bridge.sidewalkWidthRight']() {
      this.touched.sidewalkWidthRight = true
    },
    ['bridge.carriagewayWidth']() {
      this.touched.carriagewayWidth = true
    },
    ['bridge.designMinimumFreeboard']() {
      this.touched.designMinimumFreeboard = true
    },
    ['bridge.numberOfSpans']() {
      this.touched.numberOfSpans = true
    },
    ['scourResistanceClass']() {
      this.touched.scourResistanceClass = true
    },
    ['scourExposureClass']() {
      this.touched.scourExposureClass = true
    },
    ['scourResistance']() {
      this.touched.scourResistance = true
    },
    rcl(newVal) {
      this.bridge.scourResistanceClass = newVal
    },
    rel(newVal) {
      this.bridge.scourResistance = newVal
    }

  },
  data() {
    return {
      isCoordGetting: false,
      inspector_list: [],
      locationProvinces: [],
      provinceVal: null,
      locationDistricts: [],
      districtVal: null,
      locationSectors: [],
      sectorVal: null,
      locationCells: [],
      cellVal: null,
      sourceOfInformation: ["visual_inspection", "technical_document"],
      typesOfCrossing: ["bridge", "underpass", "overpass", "viaduct", "light_traffic", "culvert", "causeway"],
      principalFeatures: ["traffic", "light_traffic"],
      secondaryFeatures: ["none", "traffic", "light_traffic"],
      featuresCrossed: ["road", "river"],
      rehabilitationTypes: ["repair", "strenghtening", "reconstruction", "other"],
      assessmentTypes: ["safety", "load_bearing_capacity", "material_properties", "scour", "other"],
      isLoadingData: false,
      dataFieldMap: [
        {dataKey: "bridgeId", field: "bridge_id"},
        {dataKey: "inspectorName", field: "inspector_name"},
        //{dataKey: "dateOfInspection", field: "inspection_date"},
        {dataKey: "sourceOfInformation", field: "source_of_info"},
        {dataKey: "roadCode", field: "roadcode"},
        {dataKey: "gpsCoordinatesLat", field: "latitude"},
        {dataKey: "gpsCoordinatesLon", field: "longitude"},
        {dataKey: "bridgeName", field: "bridge_name"},
        {dataKey: "cellId", field: "cell_id"},
        {dataKey: "provinceId", field: "province_id"},
        {dataKey: "districtId", field: "district_id"},
        {dataKey: "sectorId", field: "sector_id"},
        {dataKey: "typeOfCrossing", field: " "},
        {dataKey: "principalFeature", field: "principal_feature"},
        {dataKey: "secondaryFeature", field: "secondary_feature"},
        {dataKey: "featureCrossed", field: "feature_crossed"},
        {dataKey: "numberOfSpans", field: "num_of_spans"},
        {dataKey: "overallLength", field: "total_length"},
        {dataKey: "lengthBetweenAbutments", field: "length_between_abutments"},
        {dataKey: "totalDeckWidth", field: "deck_width"},
        {dataKey: "sidewalkWidthLeft", field: "sidewalk_width_left"},
        {dataKey: "sidewalkWidthRight", field: "sidewalk_width_right"},
        {dataKey: "carriagewayWidth", field: "carriageway_width"},
        {dataKey: "approachWayWidth", field: "approachway_width"},
        {dataKey: "clearancesOnRoadCarried", field: "clearances_vertical"},
        {dataKey: "clearancesUnderTheStructure", field: "clearances_freeboard"},
        {dataKey: "designDocumentationAvailability", field: "design_doc_available"},
        {dataKey: "yearOfDesign", field: "design_year"},
        {dataKey: "designCompany", field: "design_firm"},
        {dataKey: "locationOfDesignDocuments", field: "location_design_doc"},
        {dataKey: "constructionYear", field: "construction_year"},
        {dataKey: "constructionCompany", field: "contractor"},
        {dataKey: "rehabilitationYear", field: "major_rehabilitation_year"},
        {dataKey: "rehabilitationType", field: "rehabilitation_type"},
        {dataKey: "rehabilitationTypeComment", field: "rehabilitation_type_comment"},
        {dataKey: "rehabilitationCompany", field: "rehabilitation_company"},
        {dataKey: "detailedAssessmentHaveBeenCarriedOut", field: "detailed_assessment"},
        {dataKey: "typeOfAssessment", field: "assessment_type"},
        {dataKey: "AssessmentTypeComment", field: "assessment_type_comment"},
        {dataKey: "locationOfAssessmentDocuments", field: "assessment_report_location"},
        // {dataKey: "designFirm", field: "design_firm"},
        // {dataKey: "contractor", field: "contractor"},
        // {dataKey: "constructionYear", field: "construction_year"},
        // {dataKey: "majorRehabilitationYear", field: "major_rehabilitation_year"},
        {dataKey: "designLiveLoad", field: "design_live_road"},
        {dataKey: "designCode", field: "design_code"},
        {dataKey: "superLoads", field: "super_loads"},
        {dataKey: "weightLoadRestriction", field: "weight_load_restriction"},
        {dataKey: "skewAngle", field: "skew_angle"},
        {dataKey: "designReturnPeriod", field: "design_return_period"},
        {dataKey: "designFloodDischarge", field: "design_flood_discharge"},
        {dataKey: "designMinimumFreeboard", field: "design_freeboard"},
        {dataKey: "loadManagement", field: "load_management"},
        {dataKey: "loadRating", field: "load_rating"},
        {dataKey: "seismicLoadRating", field: "seismic_load_rating"},
        {dataKey: "scourResistanceClass", field: "scour_resistance_class"},
        {dataKey: "scourExposureClass", field: "scour_exposure_class"},
        {dataKey: "substructureInFloodplain", field: "substructure_in_floodplain"},
        {dataKey: "evindenceOfScour", field: "evindence_of_scour"},
        {dataKey: "frequentFloodingDebris", field: "frequent_flooding_debris"},
        {dataKey: "scourResistance", field: "scour_resistance"},
        {dataKey: "scourFoundationType", field: "scour_foundation_type"},
        {dataKey: "scourHorizontalDispacementTop", field: "scour_horizontal_dispacement_top"},
        {dataKey: "scourHorizontalDispacementAdjacent", field: "scour_horizontal_dispacement_adjacent"},
        {dataKey: "scourRotationTop", field: "scour_rotation_top"},
        {dataKey: "scourRotationAdjacent", field: "scour_rotation_adjacent"},
        {dataKey: "asBuiltDrawingsAvailable", field: "as_built_drawings_available"},
        {dataKey: "microfilmAvailable", field: "microfilm_available"},
        {dataKey: "washedOutBefore", field: "washed_out_before"},
      ],
      bridge: {
        bridgeId: "BR####-####",
        inspectorName: "",
        //dateOfInspection: null,
        sourceOfInformation: "",
        roadCode: "",
        gpsCoordinatesLat: 47.069825,
        gpsCoordinatesLon: 28.768726,
        cellId: 0,
        provinceId: 0,
        districtId: 0,
        sectorId: 0,
        bridgeName: "",
        typeOfCrossing: "",
        principalFeature: "",
        secondaryFeature: "",
        featureCrossed: "",
        numberOfSpans: 0,
        overallLength: 0,
        lengthBetweenAbutments: 0,
        totalDeckWidth: 0,
        sidewalkWidthLeft: 0,
        sidewalkWidthRight: 0,
        carriagewayWidth: 0,
        approachWayWidth: 0,
        clearancesOnRoadCarried: 0,
        clearancesUnderTheStructure: 0,
        designDocumentationAvailability: false,
        yearOfDesign: 0,
        locationOfDesignDocuments: "",
        constructionYear: 0,
        constructionCompany: "",
        rehabilitationYear: 0,
        rehabilitationType: "",
        rehabilitationCompany: "",
        detailedAssessmentHaveBeenCarriedOut: false,
        typeOfAssessment: "",
        locationOfAssessmentDocuments: "",
        designCompany: "",
        designLiveLoad: "",
        designCode: "",
        superLoads: "",
        weightLoadRestriction: "",
        skewAngle: "",
        designReturnPeriod: "",
        designFloodDischarge: "",
        designMinimumFreeboard: "",
        loadManagement: "",
        loadRating: "",
        seismicLoadRating: "",
        scourResistanceClass: 0,
        scourExposureClass: 0,
        substructureInFloodplain: false,
        evindenceOfScour: false,
        frequentFloodingDebris: false,
        scourResistance: 0,
        scourFoundationType: "",
        scourHorizontalDispacementTop: "",
        scourHorizontalDispacementAdjacent: "",
        scourRotationTop: "",
        scourRotationAdjacent: "",
        asBuiltDrawingsAvailable: "",
        microfilmAvailable: "",
        washedOutBefore: "",
      },
      thirdCardFields: [
        {name: "overallLength", required: true, type: "number"},
        {name: "lengthBetweenAbutments", required: true, type: "number"},
        {name: "totalDeckWidth", required: true, type: "number"},
        {name: "sidewalkWidthLeft", required: true, type: "number"},
        {name: "sidewalkWidthRight", required: true, type: "number"},
        {name: "carriagewayWidth", required: true, type: "number"},
        {name: "approachWayWidth", type: "number"},
        {name: "clearancesOnRoadCarried", type: "number"},
        {name: "clearancesUnderTheStructure", type: "number"},
        {name: "designDocumentationAvailability", type: "bool", elem: "switch"},
        {name: "yearOfDesign", type: "number"},
        {name: "designCompany", type: "text"},
        {name: "locationOfDesignDocuments", type: "text"},
        {name: "constructionYear", type: "number"},
        {name: "constructionCompany", type: "text"},
        {name: "rehabilitationYear", type: "number"},
        {name: "rehabilitationType", type: "text"},
        {name: "rehabilitationTypeComment", type: "text"},
        {name: "rehabilitationCompany", type: "text"},
        {name: "detailedAssessmentHaveBeenCarriedOut", type: "bool", elem: "switch"},
        {name: "typeOfAssessment", type: "text"},
        {name: "AssessmentTypeComment", type: "text"},
        {name: "locationOfAssessmentDocuments", type: "text"},

        // {name: "designFirm", type: "text"},
        // {name: "contractor", type: "text"},
        // {name: "constructionYear", type: "number"},
        // {name: "majorRehabilitationYear", type: "number"},


        {name: "designLiveLoad", type: "text"},
        {name: "designCode", type: "text"},
        {name: "superLoads", type: "text"},
        {name: "weightLoadRestriction", type: "text"},
        {name: "skewAngle", type: "text"},
        {name: "designReturnPeriod", type: "number"},
        {name: "designFloodDischarge", type: "text"},
        {name: "designMinimumFreeboard", required: true, type: "number"},
        {name: "loadManagement", type: "text"},
        {name: "loadRating", type: "text"},
        {name: "seismicLoadRating", type: "text"}
      ],
      scourReliabilityCardSwitches: [
        {name: "substructureInFloodplain", type: "bool", elem: "switch"},
        {name: "evindenceOfScour", type: "bool", elem: "switch"},
        {name: "frequentFloodingDebris", type: "bool", elem: "switch"},
      ],
      scourReliabilityCardFields: [
        {name: "scourFoundationType", type: "text", elem: "select", options: ['deep', 'shallow', 'undefined']},
        {name: "scourHorizontalDispacementTop", type: "text", elem: "select", options: ['free', 'fixed']},
        {name: "scourHorizontalDispacementAdjacent", type: "text", elem: "select", options: ['free', 'fixed']},
        {name: "scourRotationTop", type: "text", elem: "select", options: ['free', 'fixed']},
        {name: "scourRotationAdjacent", type: "text", elem: "select", options: ['free', 'fixed']}
      ],
      modelValidations: {
        bridgeId: {
          required: true
        },
        // cellId: {
        //   required: true
        // },
        gpsCoordinatesLat: {
          required: true,
          max_value: 48,
          min_value: 46
        },
        gpsCoordinatesLon: {
          required: true,
          max_value: 30,
          min_value: 27
        },
        inspectorName: {
          required: true
        },
        dateOfInspection: {
          required: true,
          date_format: 'yyyy-MM-dd'
        },
        sourceOfInformation: {
          required: true
        },
        roadCode: {
          required: true
        },
        bridgeName: {
          required: true
        },
        overallLength: {
          required: true,
          min_value: 1
        },
        lengthBetweenAbutments: {
          required: true,
          min_value: 1
        },
        totalDeckWidth: {
          required: true,
          min_value: 1
        },

        numberOfSpans: {
          required: true,
          min_value: 1
        },
        scourResistanceClass: {
          required: true,
          min_value: 1,
          max_value: 5
        },
        scourExposureClass: {
          required: true,
          min_value: 1,
          max_value: 5
        },
        scourResistance: {
          required: true,
          min_value: 1,
          max_value: 5
        },

      },
      touched: {
        bridgeId: false,
        inspectorName: false,
        dateOfInspection: false,
        sourceOfInformation: false,
        roadCode: false,
        bridgeName: false,
        overallLength: false,
        lengthBetweenAbutments: false,
        totalDeckWidth: false,
        sidewalkWidthLeft: false,
        sidewalkWidthRight: false,
        carriagewayWidth: false,
        designMinimumFreeboard: false,
        numberOfSpans: false,
        gpsCoordinatesLat: false,
        gpsCoordinatesLon: false,
        //cellId: false,
        scourResistanceClass: false,
        scourExposureClass: false,
        scourResistance: false
      },
      bid: this.$route.params.bid,
      roadsList: []
    }
  },

  async created() {
    this.$store.dispatch('LOAD_BRIDGE_INSPECTOR_LIST').then((list) => {
      this.inspector_list = [...list]
    })
    this.$store.dispatch('LOAD_LOCATION_STRUCT').then((res) => {
      const {provinces, districts, sectors, cells} = res
      this.locationProvinces = [...provinces]
      this.locationDistricts = [...districts]
      this.locationSectors = [...sectors]
      this.locationCells = [...cells]
    })
    this.$store.dispatch("LOAD_ROAD_LIST").then((list) => {
      this.roadsList = [...list]
    })

    if (this.oper === "upd") {
      this.isLoadingData = true
      try {
        const bridgeData = await this.$store.dispatch("LOAD_BRIDGE_DATA_BY_ID", this.bid)

        Object.keys(this.bridge).forEach(dataField => {
          const mapItem = this.dataFieldMap.find(item => item.dataKey === dataField)
          if (this.bridge.hasOwnProperty(dataField) && mapItem !== undefined) {
            this.bridge[dataField] = bridgeData[mapItem.field]
          }
        })

        //this.bridge["dateOfInspection"] = this.bridge["dateOfInspection"].substring(0, 10)
        this.bridge.provinceId = parseInt(bridgeData.cell_id.toString().charAt(0))
      } finally {
        this.$nextTick(() => {
          this.isLoadingData = false
        })

      }
    }


  },
  filters: {
    UpCase: function (value) {
      return value.toUpperCase()
    },
  },
  methods: {
    getCoord() {

      if (navigator.geolocation) {
        this.isCoordGetting = true
        navigator.geolocation.getCurrentPosition(currentPosition => {
          if (currentPosition.coords.latitude) {
            this.bridge.gpsCoordinatesLat = currentPosition.coords.latitude
            this.bridge.gpsCoordinatesLon = currentPosition.coords.longitude
          }
          this.isCoordGetting = false
        })
      } else {
        console.log('Geolocation is not supported in this browser')
      }

    },

    onChangeScourExposureClass(val) {
      this.bridge.scourExposureClass = val
    },
    onScourSwitchToggled(val) {
      let scourClass = 0
      const substructureInFloodplain = this.bridge.substructureInFloodplain
      const evidenceOfScour = this.bridge.evindenceOfScour
      const frequentFloodingDebris = this.bridge.frequentFloodingDebris
      if (!substructureInFloodplain) {
        scourClass = 1
      } else if (substructureInFloodplain && !evidenceOfScour) {
        scourClass = 2
      } else if (substructureInFloodplain && evidenceOfScour && !frequentFloodingDebris) {
        scourClass = 3
      } else if (substructureInFloodplain && evidenceOfScour && frequentFloodingDebris) {
        scourClass = 4
      }
      this.bridge.scourExposureClass = scourClass
    },
    onProvinceChanged() {
      if (!this.isLoadingData) {
        this.bridge.districtId = 0
        this.bridge.sectorId = 0
        this.bridge.cellId = 0
      }
    },
    onDistrictChanged() {
      if (!this.isLoadingData) {
        this.bridge.sectorId = 0
        this.bridge.cellId = 0
      }
    },

    onSectorChanged() {
      if (!this.isLoadingData) {
        this.bridge.cellId = 0
      }
    },

    onFilesList() {
      this.$router.push({name: "bridge-files", params: {bid: this.bid}})
    },
    onExport() {
      let bridgeData = {}
      Object.keys(this.bridge).forEach(datafield => {
        const mapItem = this.dataFieldMap.find(item => item.dataKey === datafield)
        bridgeData[mapItem.field] = this.bridge[datafield]
      })
      const export_data = [Object.keys(bridgeData), Object.values(bridgeData)]
      const today = new Date().toJSON().slice(0, 10)
      const worksheet = XLSX.utils.aoa_to_sheet(export_data)
      const new_workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          `Bridge_${this.bid}_data`
      )
      XLSX.writeFile(
          new_workbook,
          `export_${this.bid}_${today}.xlsx`
      )
    },
    back() {
      this.$router.go(-1)
    },
    interventionInspection() {
      this.$router.push({name: "bridge-intervention", params: {bid: this.bid}})
    },
    conditionInspection() {
      this.$router.push({name: "bridge-condition-list", params: {aid: this.bid}})
    },
    inventoryInspection() {
      this.$router.push({name: "bridge-inventory", params: {bid: this.bid}})
    },
    validate() {
      const alert = {
        type: 'success',
        text: this.$t(`bridge.record_was_${this.oper}ed`),
        footer: ''
      }
      this.$validator.validateAll().then(async isValid => {
        if (isValid) {
          const res = {}
          Object.keys(this.bridge).forEach(
              key => {
                const dbField = this.dataFieldMap.find(item => item.dataKey === key)
                res[dbField.field] = this.bridge[key]
              }
          )
          try {

            res['clearances_vertical'] = Number(res['clearances_vertical'])
            res['longitude'] = Number(res['longitude'])
            res['latitude'] = Number(res['latitude'])
            res['clearances_freeboard'] = Number(res['clearances_freeboard'])
            res['carriageway_width'] = Number(res['carriageway_width'])
            res['deck_width'] = Number(res['deck_width'])
            res['sidewalk_width_left'] = Number(res['sidewalk_width_left'])
            res['sidewalk_width_right'] = Number(res['sidewalk_width_right'])
            res['total_length'] = Number(res['total_length'])
            res['length_between_abutments'] = Number(res['length_between_abutments'])
            res['design_year'] = Number(res['design_year'])
            res['construction_year'] = Number(res['construction_year'])
            res['major_rehabilitation_year'] = Number(res['major_rehabilitation_year'])
            res['approachway_width'] = Number(res['approachway_width'])
            res['scour_exposure_class'] = parseInt(String(res['scour_exposure_class']))
            res['scour_resistance_class'] = parseInt(String(res['scour_resistance_class']))
            res['scour_resistance'] = parseInt(String(res['scour_resistance']))
            res['as_built_drawings_available'] = res['as_built_drawings_available']===true
            res['microfilm_available'] = res['microfilm_available']===true
            res['washed_out_before'] = res['washed_out_before']===true




            if (this.oper === 'add') {
              await this.$store.dispatch("ADD_BRIDGE", res)
              alert.text = this.$t(`bridge.record_was_added`)
            } else if (this.oper === 'upd') {
              const {bridge_id, ...data} = res
              await this.$store.dispatch("UPD_BRIDGE", {bridge_id: this.bid, data})
              alert.text = this.$t(`bridge.record_was_updated`)
            } else {
              throw `bad operation type`
            }
            Swal.fire(alert).then(() => {
              //this.touched.category = false
              this.$nextTick(() => this.$validator.reset())
            })
          } catch (err) {
            alert.type = 'error'
            if (this.oper === 'add') {
              alert.text = this.$t(`bridge.record_was_not_added`)
            } else if (this.oper === 'upd') {
              alert.text = this.$t(`bridge.record_was_not_updated`)
            }
            //alert.footer = this.$t(err)
            await Swal.fire(alert)
            throw err
          }
        } else {
          alert.type = 'error'
          alert.text = this.$t(`messages.you_must_fill_in_all_required_fields`)
          Swal.fire(alert)
        }
      }, (err) => {
        alert.type = 'error'
        if (this.oper === 'add') {
          alert.text = this.$t(`bridge.record_was_not_added`)
        } else if (this.oper === 'upd') {
          alert.text = this.$t(`bridge.record_was_not_updated`)
        }

        console.log(err)
        Swal.fire(alert)
      })
    }
  },
  computed: {
    doNavigatorEnable() {
      return navigator.geolocation
    },
    ft1() {
      return this.bridge.scourFoundationType === 'deep' ? 1 : 2
    },
    ft2() {
      return this.bridge.scourHorizontalDispacementTop === 'free' ? 3 : 0
    },
    jtr() {
      return this.bridge.scourHorizontalDispacementTop === 'free' ? 0 :
          this.bridge.scourHorizontalDispacementAdjacent !== 'free' ? 0 :
              this.bridge.scourRotationTop === 'free' ? 2 : 0
    },
    ajr() {
      return this.bridge.scourHorizontalDispacementTop === 'free' ? 0 :
          this.bridge.scourRotationAdjacent === 'free' ? 1 : 0
    },
    // RCL
    rcl() {
      return this.ft1 + this.ft2 + this.jtr + this.ajr
    },
    rel() {
      let relVal
      const ecl = this.bridge.scourExposureClass
      const rcl = this.bridge.scourResistanceClass

      switch (true) {
        case(ecl <= 2 && rcl == 1):
          relVal = 1
          break;
        case(ecl > 2 && rcl == 1):
          relVal = 2
          break;
        case(ecl == 1 && rcl <= 3):
          relVal = 1;
          break;
        case(ecl == 2 && (rcl == 2 || rcl == 3)):
          relVal = 2;
          break;
        case(ecl == 3 && rcl == 2):
          relVal = 2;
          break;
        case(ecl == 4 && rcl == 2):
          relVal = 3;
          break;
        case(ecl == 4 && rcl == 3):
          relVal = 4;
          break;
        case(ecl == 3 && rcl == 3):
          relVal = 3;
          break;
        case(ecl == 4 && rcl == 4):
          relVal = 5;
          break;
        case(ecl == 3 && rcl == 4):
          relVal = 4;
          break;
        case(ecl == 2 && rcl == 4):
          relVal = 3;
          break;
        case(ecl == 1 && rcl == 4):
          relVal = 2;
          break;
        case(ecl == 1 && rcl == 5):
          relVal = 3;
          break;
        case(ecl == 2 && rcl == 5):
          relVal = 4;
          break;
        case(ecl >= 3 && rcl == 5):
          relVal = 5;
          break;
        default:
          relVal = 0
      }
      return relVal
    },
    districtsReduced() {
      const val = this.bridge.provinceId
      const cf = Math.pow(10, 1)
      return val ? this.locationDistricts.filter(district => district.id >= val * cf && district.id < val * cf + cf) : []
    },
    sectorsReduced() {
      const val = this.bridge.districtId
      const cf = Math.pow(10, 2)
      return val ? this.locationSectors.filter(sector => sector.id >= val * cf && sector.id < val * cf + cf) : []
    },
    cellsReduced() {
      const val = this.bridge.sectorId
      const cf = Math.pow(10, 2)
      return val ? this.locationCells.filter(cell => cell.id >= val * cf && cell.id < val * cf + cf) : []
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/md/_variables.scss';

.card-title {
  font-size: 17px;
}


.md-menu-content {
  max-width: 480px;
}

.md-card .md-card-actions {
  border: none;
}

.toolbar-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 25px;

  cursor: pointer;
  display: flex;

  div {
    padding-left: 10px;
    color: $gray-color;

    &:hover {
      color: $brand-primary;
    }
  }
}

/* Define an animation behavior */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
  /* Apply 'spinner' keyframes looping once every second (1s)  */
  animation: spinner 1s linear infinite;
  color: $brand-primary !important;
}
</style>
